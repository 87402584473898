import React from 'react';
import { Field } from 'formik';

import './toggle.style.scss';

export interface ToggleProps {
    title: string;
    name: string;
    value: string;
    fieldType: string;
}

export const Toggle = ({ title, name, value, fieldType }: ToggleProps) => {
    return (
        <label className="d-flex justify-content-between toggle__label align-items-center mb-0">
            <span>{title}</span>
            <Field type={fieldType} className="toggle__control" name={name} value={value} />
            <div className="toggle__switch"></div>
        </label>
    );
};
