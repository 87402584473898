import React, { useEffect, useState } from 'react';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import ProfileLayout from 'components/layouts/profile/profile.layout';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import BirdiModal from 'components/birdi-modal/birdi-modal';
import { accountNotificationsSelector, accountProfileSelector } from 'state/account/account.selectors';
import { accountFetchNotificationsRoutine, accountUpdateNotificationsRoutine } from 'state/account/account.routines';
import Button from 'ui-kit/button/button';
import { Notifications } from 'types/notifications';
import './notifications.style.scss';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import UpdateProfileModalContent, {
    FailureUpdateProfileModalContent
} from './intra-page-items/profile-update-modal.item';
import { Toggle, ToggleProps } from 'ui-kit/toggle/toggle';

const NotificationSwitch = ({ title, name, value, fieldType }: ToggleProps) => {
    return (
        <div className="notification-switch">
            <Toggle title={title} name={name} value={value} fieldType={fieldType} />
        </div>
    );
};

const NotificationsSection = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const profileObject = useSelector(accountProfileSelector);
    const allNotificationPreferences = useSelector(accountNotificationsSelector);
    const [notificationsLoaded, setNotificationsLoaded] = useState(false);
    const [notificationPreferences, setNotificationPreferences] = useState({
        phoneNotifications: false,
        textNotifications: false,
        emailNotifications: false
    });

    const allNotifications = {
        textNotifications: ['NewScriptText', 'RefillReminderText', 'OrderShippedText'],
        emailNotifications: ['NewScriptEmail', 'RefillReminderEmail', 'OrderShippedEmail'],
        phoneNotifications: ['NewScriptPhone', 'RefillReminderPhone', 'OrderShippedPhone']
    };

    useEffect(() => {
        dispatch(
            accountFetchNotificationsRoutine.trigger({
                onSuccess: (data) => {
                    const textNotifications = allNotifications.textNotifications
                        .map((notification) => {
                            return data[notification];
                        })
                        .every((notification) => notification === true);
                    const emailNotifications = allNotifications.emailNotifications
                        .map((notification) => {
                            return data[notification];
                        })
                        .every((notification) => notification === true);
                    const phoneNotifications = allNotifications.phoneNotifications
                        .map((notification) => {
                            return data[notification];
                        })
                        .every((notification) => notification === true);
                    setNotificationPreferences({
                        textNotifications,
                        emailNotifications,
                        phoneNotifications
                    });
                    setNotificationsLoaded(true);
                }
            })
        );
    }, [profileObject]);

    const createUpdatedNotifications = (notifications: string, value: boolean) => {
        const obj = allNotifications[notifications].reduce((o, key) => ({ ...o, [key]: value }), {});
        return obj;
    };

    const handleFormSubmit = (values, submitProps) => {
        const allNotificationsKeys = Object.keys(allNotifications);
        const allNotificationsPayload = allNotificationsKeys.map((notifications) => {
            if (values.notifications.includes(notifications)) {
                return createUpdatedNotifications(notifications, true);
            } else {
                return createUpdatedNotifications(notifications, false);
            }
        });
        const updatedNotifications = Object.assign(...allNotificationsPayload);
        const ConsentAutoCalls = values.notifications !== 'none' ? true : false;
        const updatedNotificationsPayload = {
            ...allNotificationPreferences,
            ...updatedNotifications,
            ConsentAutoCalls
        };
        dispatch(
            accountUpdateNotificationsRoutine.trigger({
                ...updatedNotificationsPayload,
                onSuccess: () => {
                    submitProps.resetForm({ values });
                    dispatch(
                        openModal({
                            showClose: true,
                            bodyContent: (
                                <UpdateProfileModalContent area={t('modals.updateProfile.areas.notifications')} />
                            ),
                            ctas: [
                                {
                                    label: t('modals.updateProfile.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        })
                    );
                },
                onFailure: () => {
                    submitProps.resetForm();
                    dispatch(
                        openModal({
                            showClose: true,
                            bodyContent: (
                                <FailureUpdateProfileModalContent
                                    area={t('modals.updateProfile.areas.notifications')}
                                />
                            ),
                            ctas: [
                                {
                                    label: t('modals.updateProfile.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    const initialValues = () => {
        const initialSelected = Object.keys(notificationPreferences)
            .filter((preference) => {
                return notificationPreferences[preference] === true;
            })
            .join('');
        return initialSelected.length !== 0 ? initialSelected : 'none';
    };

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.notifications.eyebrowText`)}
            title={t(`pages.profile.notifications.title`)}
            heading={t(`pages.profile.notifications.heading`)}
        >
            {notificationsLoaded && (
                <Container fluid>
                    <Formik<Partial<Notifications>>
                        onSubmit={(values, submitProps) => {
                            handleFormSubmit(values, submitProps);
                        }}
                        initialValues={{
                            notifications: initialValues()
                        }}
                        enableReinitialize
                    >
                        {(formik: any) => (
                            <Form id="notification-form" onSubmit={formik.handleSubmit} autoComplete="off">
                                {Object.keys(allNotifications).map((notificationType) => {
                                    return (
                                        <NotificationSwitch
                                            key={`notification-switch-${notificationType}`}
                                            title={t(
                                                `pages.profile.notifications.notificationTypes.${notificationType}`
                                            )}
                                            name={'notifications'}
                                            value={notificationType}
                                            fieldType="radio"
                                        />
                                    );
                                })}
                                <NotificationSwitch
                                    title={t(`pages.profile.notifications.notificationTypes.none`)}
                                    name={'notifications'}
                                    value={'none'}
                                    fieldType="radio"
                                />
                                <Row className={`d-flex mt-3 justify-content-between flex-column`}>
                                    <Col className={'text-center'}>
                                        <Button
                                            className="sm-full md-full"
                                            disabled={!formik.dirty}
                                            label={t('pages.profile.notifications.labels.submit')}
                                            variant="primary"
                                            type="submit"
                                            onClick={formik.handleSubmit}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Container>
            )}
            <BirdiModal />
        </ProfileLayout>
    );
};

export default NotificationsSection;
